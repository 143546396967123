import React from "react";
import PropTypes from "prop-types";

import Layout from "../../components/layout/Layout";
import SEO from "../../components/seo/Seo";
import Weebly from "../../components/integration/weebly/Weebly";
import i18nContext from "../../components/i18n/i18n.context";
import Page from "../../components/page/Page";

function WeeblyPage({ location }) {
  return (
    <i18nContext.Provider value={{ locale: "fr" }}>
      <Layout>
        <SEO
          keywords={["gatsby", "react"]}
          location={location}
          title="BusinessLocator dans Weebly"
        />
        <Page>
          <Weebly />
        </Page>
      </Layout>
    </i18nContext.Provider>
  );
}

WeeblyPage.propTypes = {
  location: PropTypes.object.isRequired, //eslint-disable-line react/forbid-prop-types
};

export default WeeblyPage;
